'use strict';

angular.module("aerosApp")
    .factory('editFiberGroupModal', ["$uibModal", 'aerosApi', function ($uibModal, aerosApi) {

        function open(fiberGroup) {
            var modal = $uibModal.open({
                size: "md",
                templateUrl: "/static/templates/dashboard/project/modal/addEditFiberGroupModal.html",
                controller: "EditFiberGroupModalCtrl",
                resolve: {
                    existingFiberGroup: function () {
                        return fiberGroup;
                    },
                    config: ["configService", function (configService) {
                        return configService.getPromise();
                    }],
                    fiberTypeStructure: function () {
                        return aerosApi.getFiberTypes().then(function (results) {
                            return results.data;
                        });
                    }
                }
            });

            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("EditFiberGroupModalCtrl", ["$scope", "$uibModalInstance", "existingFiberGroup", 'fiberTypes',
        "FiberGroupService", 'config', 'fiberTypeStructure', "UserProfileService", "UnitsMeasurementService",
        function ($scope, $uibModalInstance, existingFiberGroup, fiberTypes, FiberGroupService, config,
                  fiberTypeStructure, UserProfileService, UnitsMeasurementService) {

            angular.extend($scope, {
                getMPOPolarity: getMPOPolarity
            });

            $scope.formAction = {
                edit: true,
                add: false
            };
            $scope.lossDistUnit = UserProfileService.get().user.preferredLossDistanceUnit;
            $scope.converters = UnitsMeasurementService.getConverters('dB/km|' + $scope.lossDistUnit);

            if ($scope.converters.showUnitConverter) {
                $scope.convert = $scope.converters.showUnitConverter;
            } else {
                $scope.convert = function (value) {
                    return value;
                };
            }

            $scope.waveLengthsBySubFiberType = fiberTypeStructure;
            $scope.networkTypes = config.getNetworkTypes();
            $scope.links = config.getLinkTypes();
            $scope.fiberTypes = fiberTypes;
            $scope.fiberGroup = {
                id: existingFiberGroup.id,
                name: existingFiberGroup.name,
                fiberType: existingFiberGroup.fiberType,
                fiberMode: existingFiberGroup.fiberMode,
                fiberCount: existingFiberGroup.fiberCount,
                startFiber: existingFiberGroup.startFiber,
                connectionCount: existingFiberGroup.connectionCount,
                spliceCount: existingFiberGroup.spliceCount,
                end1Name: existingFiberGroup.end1Name,
                end1Connector: {
                    connectorType: (existingFiberGroup.end1Connector && existingFiberGroup.end1Connector.connectorType) ? existingFiberGroup.end1Connector.connectorType : "None",
                    linkType: (existingFiberGroup.end1Connector && existingFiberGroup.end1Connector.linkType) ? existingFiberGroup.end1Connector.linkType : "None"
                },
                end2Name: existingFiberGroup.end2Name,
                end2Connector: {
                    connectorType: (existingFiberGroup.end2Connector && existingFiberGroup.end2Connector.connectorType) ? existingFiberGroup.end2Connector.connectorType : "None",
                    linkType: (existingFiberGroup.end2Connector && existingFiberGroup.end2Connector.linkType) ? existingFiberGroup.end2Connector.linkType : "None"
                },
                mpoPolarity: existingFiberGroup.mpoPolarity,
                comment: existingFiberGroup.comment,
                helixFactor: +existingFiberGroup.helixFactor,
                // subFiberType: existingFiberGroup.subFiberType,
                waveLengths: existingFiberGroup.subFiberType.values
            };

            $scope.fiberGroup.subFiberType = $scope.waveLengthsBySubFiberType[existingFiberGroup.fiberMode][existingFiberGroup.subFiberType.name];

            $scope.showAdvanced = false;

            $scope.cancel = function () {
                $uibModalInstance.dismiss();
            };

            $scope.saveFiberGroup = function () {
                $uibModalInstance.close($scope.fiberGroup);
            };

            $scope.toggleAdvanced = function () {
                $scope.showAdvanced = !$scope.showAdvanced;
            };

            $scope.ui = {};

            function getMPOPolarity(mpoPolarity) {
                return config.getMpoTypes().find(function (item) {
                    return item.value === mpoPolarity;
                });
            }

            $scope.setLink = function (end, link) {
                if ($scope.selectedLinks[end][link]) {
                    $scope.fiberGroup[end + "Connector"].linkType = $scope.links[link].value;
                    // only allow one link type per end to be checked
                    $scope.selectedLinks[end] = _.map($scope.selectedLinks[end], function (linkSelected, i) {
                        return i === link;

                    });
                } else {
                    // set linkType to "None" when checkbox is de-selected
                    $scope.fiberGroup[end + "Connector"].linkType = "None";
                }
            };

            (function initLinks() {
                $scope.selectedLinks = {
                    end1: new Array($scope.links.length),
                    end2: new Array($scope.links.length)
                };

                ['end1', 'end2'].forEach(function(end) {
                    $scope.links.forEach(function(link,linkIndex) {
                        if ($scope.fiberGroup[end+"Connector"].linkType === link.value) {
                            $scope.selectedLinks[end][linkIndex] =  true;
                        }
                    })
                });

            })();

        }]);
